<template>
    <div>
        <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
            <b-row>
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('classroom_name')">
                        <classroom-selectbox
                            v-model="datatable.queryParams.filter.name"
                            value-type="name"
                            />
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('date')">
                        <select-date v-model="date"/>
                    </b-form-group>
                </b-col>
            </b-row>
        </datatable-filter>
        <datatable :isLoading.sync="datatable.isLoading"
                   :columns="datatable.columns"
                   :rows="datatable.rows"
                   :total="datatable.total"
                   :queryParams="datatable.queryParams"
                   @on-page-change="onPageChange"
                   @on-sort-change="onSortChange"
                   @on-per-page-change="onPerPageChange"
                   v-show="datatable.showTable"
        />
        <CommonModal ref="showFormModal" size="xxl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear" v-if="checkPermission('reservation_weeklyprogram')">
            <template v-slot:CommonModalTitle>
                {{ classroom_name}}
            </template>
            <template v-slot:CommonModalContent>
                <show-form :formId="formId"
                           :date="date"
                           v-if="formProcess=='show'"/>
            </template>
        </CommonModal>
    </div>
</template>
<script>
    // Component
    import BuildingSelectbox from '@/components/interactive-fields/BuildingSelectbox'
    import CampusSelectbox from '@/components/interactive-fields/CampusSelectbox'
    import CommonModal from '@/components/elements/CommonModal'
    import DatatableFilter from '@/components/datatable/DatatableFilter'
    import Datatable from '@/components/datatable/Datatable'

    // Pages
    import ShowForm from './ShowForm'

    // Services
    import ReservationService from '@/services/ReservationService'

    // Other
    import qs from 'qs'
    import ClassroomSelectbox from "@/components/interactive-fields/ClassroomSelectbox.vue";
    import moment from "moment/moment";

    export default {
        components: {
            ClassroomSelectbox,
            BuildingSelectbox,
            CampusSelectbox,
            CommonModal,
            DatatableFilter,
            Datatable,

            ShowForm
        },
        data() {
            return {
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('show'),
                                    class: 'ri-edit-box-line',
                                    permission: 'reservation_weeklyprogram',
                                    hidden: false,
                                    callback: ({id,name}) => {
                                        this.showFormShow(id,name);
                                    }
                                }
                            ]
                        },
                        {
                            label: this.toUpperCase(this.spaceToBR(this.$t('campus'))),
                            field: 'campus_name',
                            sortable: false,
                            formatFn: (value, row) => {
                                return row.campus_name
                            }
                        },
                        {
                            label: this.toUpperCase(this.$t('building')),
                            field: 'building_name',
                            sortable: false,
                            formatFn: (value, row) => {
                                return row.building_name
                            }
                        },
                        {
                            label: this.toUpperCase(this.$t('classroom')),
                            field: 'name',
                            sortable: false,
                            formatFn: (value, row) => {
                                return row.name
                            }
                        },
                        {
                            label: this.toUpperCase(this.$t('capacity')),
                            field: 'capacity',
                            sortable: false,
                            thClass: 'text-center',
                            tdClass: 'text-center'
                        }
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {
                            campus_id: null,
                            building_id: null,
                            name: null
                        },
                        page: 1,
                        limit: 20
                    }
                },
                formId: null,
                formProcess: null,
                classroom_name:null,
                date:null
            }
        },
        methods: {
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            filterClear() {
                this.datatable.queryParams.filter = {
                    campus_id: null,
                    building_id: null,
                    name: null
                }
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field;
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return ReservationService.classrooms(config)
                                         .then((response) => {
                                             this.datatable.rows = response.data.data;
                                             this.datatable.total = response.data.pagination.total;
                                         })
                                         .catch((e) => {
                                             this.showErrors(e);
                                         })
                                         .finally(() => {
                                             this.datatable.isLoading = false;
                                         });
            },

            // Clear
            formClear() {
                this.formId = null
                this.formProcess = null
            },

            // Show Form
            showFormShow(id,name) {
                this.formId = id
                this.classroom_name = name
                this.formProcess = 'show'
                this.$refs.showFormModal.$refs.commonModal.show()
            }
        },
        created() {
            this.date=moment().startOf('week').add(+1, 'days').format('MM/DD/YYYY');
        }
    }
</script>
