import Api from '@/services/Index';

// Reservation
const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/reservations', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/reservations/' + id);
}

const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/reservations', data);
}

const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.patch('/reservations/' + id, formData);
}

const del = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/reservations/' + id);
}

// Classroom Schedule
const classrooms = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/reservation/classrooms', config);
}
const classroomSchedule = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/reservation/weekly', config);
}

// Empty Classrooms
const emptyClassrooms = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/reservation/empty/classrooms', config);
}

// Conflict Report
const conflictReport = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/reservation/conflicts', config);
}

const conflictReportExcel = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/reservation/conflicts/excel-export', config);
}

export default {
    getAll,
    get,
    store,
    update,
    del,

    classrooms,
    classroomSchedule,

    emptyClassrooms,

    conflictReport,
    conflictReportExcel,
}
