<template>
    <div>
        <loading v-show="loading"/>
        <div v-show="!loading">
            <vue-cal :selected-date="startDate"
                     :time-cell-height="30"
                     :time-step="30"
                     :disable-views="['years', 'year', 'month','day']"
                     :hide-weekdays="[]"
                     :events="timetables"
                     :time-from="timeFrom"
                     :time-to="timeTo"
                     @view-change="changeView($event)"
                     :on-event-click="showVueCalModal"
                     active-view="week"
            />
            <CommonModal ref="vueCalModal" size="sm">
                <template v-slot:CommonModalTitle>
                    <i style="font-size: 24px;" class="ri-calendar-event-line"></i>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <b-row>
                            <b-col md="12" class="mb-2">{{selectedEvent.date}}</b-col>
                            <b-col md="12" class="mb-2">{{selectedEvent.title}}</b-col>
                            <b-col md="12" class="mb-2">{{selectedEvent.start_time}} - {{selectedEvent.end_time}}</b-col>
                            <b-col md="12" v-html="selectedEvent.content"></b-col>
                        </b-row>
                    </div>
                </template>
            </CommonModal>
        </div>
    </div>
</template>
<script>
    // Services
    import ReservationService from '@/services/ReservationService';

    // Components
    import Loading from '@/components/elements/Loading'
    import CommonModal from "@/components/elements/CommonModal";

    // Timetable
    import VueCal from 'vue-cal'
    import 'vue-cal/dist/vuecal.css'
    import '/src/locales/en/vuecal.js'

    // Other
    import moment from 'moment';

    export default {
        components: {
            VueCal,
            Loading,
            CommonModal
        },
        props: {
            formId: {
                type: Number
            },
            date: {
                type: String
            }
        },
        created() {
            this.startDate=this.date;
            this.classroomSchedule();
        },
        data() {
            return {
                timetables: [],

                timeFrom: 8 * 60,
                timeTo: 24 * 60,

                loading: false,
                selectedEvent: {},
                startDate:null
            }
        },
        methods: {
            changeView(event) {
                this.startDate=event.startDate.format('MM/DD/YYYY');
                this.classroomSchedule();
            },
            classroomSchedule() {
                const config = {
                    classroom_id: this.formId,
                    date: this.startDate
                };
                this.timetables = [];
                this.loading = true;
                ReservationService.classroomSchedule(config)
                                  .then(response => {
                                      let schedule = response.data.data;
                                      let days = Object.values(schedule);

                                      if (days.length) {
                                          Object.values(schedule)
                                                .forEach((day) => {
                                                    if (day.length) {
                                                        day.forEach((lesson) => {
                                                            this.timetables.push({
                                                                start: lesson.date + ' ' + lesson.start_time,
                                                                end: lesson.date + ' ' + lesson.end_time,
                                                                title: lesson.name,
                                                                content: ''
                                                            });
                                                        })
                                                    }
                                                })
                                      }
                                  })
                                  .catch((e) => {
                                      this.showErrors(e);
                                  })
                                  .finally(() => {
                                      this.loading = false;
                                  })
            },

            showVueCalModal(event, e){
                this.selectedEvent = event
                moment.locale('en')
                this.selectedEvent.date = moment(event.start).format("MM/DD/YYYY")
                this.selectedEvent.start_time = moment(event.start).format("LT")
                this.selectedEvent.end_time = moment(event.end).format("LT")
                this.$refs.vueCalModal.$refs.commonModal.show()
                e.stopPropagation()
            }
        }
    }
</script>

<style>
    .vuecal {
        border: 1px solid #e9ecef;
        box-shadow: none;
    }

    .vuecal__menu {
        display: none;
    }

    .vuecal__weekdays-headings, .vuecal__all-day {
        background: #e9ecef;
    }

    .vuecal__event {
        background-color: #e9ecef;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid #cccccc;
    }

    .vuecal__cell--selected {
        background: none !important;
    }

    .vuecal__cell--current, .vuecal__cell--today {
        background: none !important;
    }

    .vuecal__view-btn--active {
        background: #e9ecef;
        border-bottom: 0;
    }

    .vuecal__event-title, .vuecal__event-time, .vuecal__event-content {
        font-size: 13px;
        font-weight: 500;
    }

    @media screen and (max-width: 992px) {
        .vuecal__event-title, .vuecal__event-time, .vuecal__event-content {
            font-size: 10px;
            font-weight: 500;
        }
    }
</style>
