<template>
    <div class="spinner over w-100 bg-white" v-show="show" style="min-height: 150px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
            <b-spinner label="Spinning" class="text-primary"></b-spinner>
            <div class="mt-2" v-if="descriptionShow && description">
                {{ description }}
            </div>
        </div>
    </div>
</template>
<script>
    import i18n from '@/plugins/i18n'

    export default {
        props: {
            show: {
                type: Boolean,
                default: false
            },
            descriptionShow: {
                type: Boolean,
                default: false
            },
            description: {
                type: String,
                default: () => {
                    return i18n.t('loading')
                }
            }
        }
    }
</script>
