<template>
    <div>
        <multiselect
            :placeholder="$t('select')"
            v-model="selected"
            :options="options"
            :class="validateError != '' ? 'box-border-color' : ''"
            @input="handleInput($event, multiple)"
            label="text"
            track-by="value"
            :multiple="multiple"
            :select-label="''"
            :selected-label="''"
            :deselect-label="''"
            :searchable="true"
            :disabled="disabled"
            :close-on-select="!multiple"
            :clear-on-select="!multiple"
        >
            <template slot="selection" slot-scope="{ values, isOpen }" v-if="multiple">
				<span class="multiselect__single" v-if="values.length && !isOpen">
					{{ translateNSelected(values) }}
				</span>
            </template>
            <span slot="noOptions">{{ $t("no_options") }}</span>
            <span slot="noResult">{{ $t("no_result") }}</span>
        </multiselect>
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
    </div>
</template>

<script>
// Helpers
import translateNSelected from "@/helpers/translateNSelected";
import handleInput from "@/helpers/handleInput";
import setSelected from "@/helpers/setSelected";

// Services
import ClassroomService from "@/services/ClassroomService";

// Other
import qs from "qs";

export default {
    props: {
        value: {
            default: null,
        },
        validateError: {
            type: String,
            default: '',
        },
        building_id: {
            type: Number,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        returnType: {
            default: "Number",
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        classroomType: {
            type: String,
            default: null
        },
        valueType: {
            type: String,
            default: 'id'
        }
    },

    data() {
        return {
            selected: null,
            options: [],
        }
    },
    watch: {
        building_id: {
            immediate: true,
            handler: function (newValue, oldValue) {
                if (newValue != oldValue) {
                    this.getOptions();

                } else {
                    this.clearOptions();
                }
            },
        },
        value: function (newValue) {
            this.selected = this.setSelected(newValue, this.options, this.multiple)
        }
    },
    beforeMount() {
        setTimeout(()=>{
            if(this.building_id != null) return
            this.getOptions();
        },1000)
    },
    methods: {
        translateNSelected: translateNSelected,
        handleInput: handleInput,
        setSelected: setSelected,
       getOptions() {
                this.clearOptions();
                let filter = {}
                this.building_id != null ? filter.building_id = this.building_id : false
                this.classroomType != null ? filter.type = this.building_id : false
                let config = {
                    params: {
                        limit: -1,
                        filter: filter,
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                };
                ClassroomService.getAll(config)
                    .then((response) => {
                        const data = response.data.data;
                        data.map((item) => {
                            this.options.push({
                                value: item[this.valueType],
                                text: item.name,
                            });
                        });
                    })
                    .then(() => {
                        this.selected = this.setSelected(this.value, this.options, this.multiple)
                    })
        },
        clearOptions() {
            this.options = [];
            this.selected = null;
        }
    }
}
</script>
